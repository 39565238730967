.PhoneInputInput{
    height: 40px;
}
// .react-select-4-placeholder{
//     width: 180px;
// }
// .PhoneInputInput:focus {
//     border-color: rgb(62, 137, 208); /* 聚焦时的边框颜色 */
//     box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* 聚焦时的阴影效果 */
//   }
//   .PhoneInput--focus,.PhoneInput--focus  .PhoneInputInput{
//     border-color: rgb(62, 137, 208) !important; /* 聚焦时的边框颜色 */
//     box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* 聚焦时的阴影效果 */
//   }

.ck-editor__editable {
    min-height: 12rem;
    // padding-left: 25px !important;
}

.blog-editor .ck-editor__editable {
    min-height: 20rem;
    // padding-left: 25px !important;
}

// iframe {
//     width: 100%;
//     aspect-ratio: 1.777;
//     /* 宽高比 1.777 */
//     border: 0;
// }

/* 为小屏幕调整 */

@media only screen and (max-width: 600px) {
    .responsive-video iframe {
        width: 100%;
        min-height: 220px;
        height: auto;
    }
    .react-select-4-placeholder{
        width: 120px;
    }
}

@media only screen and (min-width: 1280px) {
    .react-select-4-placeholder{
        width: 180px;
    }
}

.ck.ck-dropdown .ck-dropdown__arrow {
    z-index: 0 !important;
}

.ck-editor__editable ul,
.ck-editor__editable ol,
.chakra-text ul,
.chakra-text ol {
    padding-left: 25px !important;
}

h1 {
    font-size: 2em !important;
    font-weight: bold !important;
}

h2 {
    font-size: 1.5em;
    font-weight: bold !important;
}

h3 {
    font-size: 1.17em;
    font-weight: bold !important;
}

h4 {
    font-size: 1em;
    font-weight: bold !important;
}